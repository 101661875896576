<template>
<div class="main">
  <VxeOrgTree service="/sys/sysOrganization/treeQueryAuthCommon/communtiy" class="main_left" :expandAll="true" />
  <div class="main_right">
    <VxeBasicTable ref="xGrid" :gridOptions="gridOptions" :formOptions="formOptions" :searchOptions="searchOptions" :defaultTrigger="false">
    </VxeBasicTable>
  </div>
  <vxe-modal ref="xModal" title="指派处理人" width="800" height="600" show-zoom>
    <InspectionHandler :currNode="currNode" :selectRow="selectRow" />
  </vxe-modal>
</div>
</template>

<script>
import { mapActions } from 'vuex';
import InspectionHandler from "./InspectionHandler";
export default {
  components: {
    InspectionHandler
  },
  data() {
    return {
      loading: false,
      selectRow: {},
      currNode: {},
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60
          },
          {
            type: "checkbox",
            width: 60
          },
          {
            field: "inspectionPlanName",
            title: "巡检计划名称",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入巡检计划名称"
              }
            }
          },
          {
            field: "inspectionPlanPeriod",
            title: "巡检周期",
            minWidth: 160,
            editRender: {
              name: "$select",
              optionTypeCode: "inspectionPlanPeriod",
              props: {
                maxlength: 24,
                placeholder: "请选择巡检周期"
              }
            }
          },
          {
            field: "signType",
            title: "签到方式",
            minWidth: 160,
            editRender: {
              name: "$select",
              optionTypeCode: "signType",
              props: {
                maxlength: 24,
                placeholder: "请选择签到方式"
              }
            }
          },
          {
            field: "startTime",
            title: "开始时间",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                type: "date",
                placeholder: "请输入开始时间"
              }
            }
          },
          {
            field: "endTime",
            title: "结束时间",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                type: "date",
                placeholder: "请输入结束时间"
              }
            }
          },
          {
            field: "remark",
            title: "描述",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 3,
              }
            },
          },
          {
            fixed: "right",
            title: "操作",
            width: 220,
            editRender: {
              name: "$VxeGridButtons",
              lists: [{
                  name: "assignHandler",
                  status: "primary",
                  label: "指派处理人",
                  events: {
                    click: (row) => {
                      this.selectRow = row;
                      this.$refs.xModal.open();
                    },
                  },
                },
                {
                  name: "edit",
                  status: "primary",
                  label: "修改",
                  eventName: "queryEvent",
                },
                {
                  name: "delete",
                  status: "danger",
                  label: "删除",
                  eventName: "removeEvent",
                },
              ],
            }
          }
        ],
        editConfig: {},
        exportConfig: {},
        importConfig: {},
      },
      formOptionPlanStaff: {
        width: 600,
        titleWidth: 100,
        items: [{
            field: "staffName",
            title: "巡检人姓名",
            span: 24,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请选择巡检人",
              },
              events: {
                click: () => {
                  this.$refs.xModal.open();
                },
              },
            },
          },
          {
            field: "startTime",
            title: "开始时间",
            span: 24,
            itemRender: {
              name: "$input",
              props: {
                type: "time",
                labelFormat: "HH:mm",
                valueFormat: "HH:mm",
                placeholder: "请选择开始时间",
              },
            },
          },
          {
            field: "endTime",
            title: "结束时间",
            span: 24,
            itemRender: {
              name: "$input",
              props: {
                type: "time",
                labelFormat: "HH:mm",
                valueFormat: "HH:mm",
                placeholder: "请输入结束时间"
              }
            }
          },
          {
            align: "center",
            span: 24,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "提交",
                    status: "primary"
                  }
                },
                {
                  props: {
                    type: "reset",
                    content: "取消"
                  }
                }
              ]
            }
          }
        ],
        //表单校验规则
        rules: {
          staffName: [{ required: true, message: "请选择巡检人" }],
          startTime: [{ required: true, message: "请选择开始时间" }],
          endTime: [{ required: true, message: "请输入结束时间" }],
        },
        // 新增功能字段初始化
        data: {
          communityId: "",
          staffName: "",
          startTime: "",
          endTime: "",
        },
      },
      formOptions: {
        // 新增功能字段配置
        width: 800,
        titleWidth: 100,
        items: [{
            field: "parentId",
            title: "所属小区",
            span: 24,
            itemRender: {
              name: "$VxeSelectTree",
              expandAll: true,
              props: {
                placeholder: "请选择所属小区",
              },
              events: {
                click: (params, node) => {
                  let { data } = params;
                  if (node.type == '1001') {
                    data["parentId"] = node.id;
                  } else {
                    this.$message.error('请选择所属小区')
                  }
                }
              }
            },
          },
          {
            field: "inspectionPlanName",
            title: "巡检计划",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入巡检计划"
              }
            }
          },
          {
            field: "inspectionPlanPeriod",
            title: "巡检周期",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "inspectionPlanPeriod",
              props: {
                placeholder: "请选择巡检周期"
              }
            }
          },
          {
            field: "signType",
            title: "签到方式",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "signType",
              props: {
                maxlength: 24,
                placeholder: "请选择签到方式"
              }
            }
          },
          {
            field: "startTime",
            title: "开始时间",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: "date",
                placeholder: "请选择开始时间",
              },
            },
          },
          {
            field: "inspectionRouteName",
            title: "路线名称",
            span: 12,
            itemRender: {
              name: "$VxeSelectInspectionRoute",
              props: {
                maxlength: 24,
                placeholder: "请选择路线名称",
              },
              events: {
                change: (params, node) => {
                  let { data, property } = params;
                  data['inspectionRouteId'] = node.id;
                  data[property] = node.routeName;
                  console.log(data[property])
                },
              },
            },
          },
          {
            field: "endTime",
            title: "结束时间",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: "date",
                placeholder: "请输入结束时间"
              }
            }
          },
          {
            field: "enabled",
            title: "状态",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "enabledStatus",
              props: {
                placeholder: "请选择状态"
              }
            }
          },
          {
            field: "remark",
            title: "描述",
            span: 24,
            itemRender: {
              name: "$textarea",
              props: {
                showWordCount: true,
                maxlength: "300",
                resize: "none",
                placeholder: "请输入描述内容"
              }
            }
          },
          {
            align: "center",
            span: 24,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "提交",
                    status: "primary"
                  }
                },
                {
                  props: {
                    type: "reset",
                    content: "取消"
                  }
                }
              ]
            }
          }
        ],
        //表单校验规则
        rules: {
          parentId: [{ required: true, message: "请选择所属小区" }],
          orderCode: [{ required: true, message: "请输入排序号" }],
          enabled: [{ required: true, message: "请选择状态" }],
          inspectionRouteName: [{ required: true, message: "请选择路线名称" }],
          signType: [{ required: true, message: "请选择签到方式" }],
          inspectionPlanName: [{ required: true, message: "请选择巡检计划" }],
          inspectionPlanPeriod: [{ required: true, message: "请选择巡检周期" }],
          startTime: [{ required: true, message: "请选择开始时间" }],
          endTime: [{ required: true, message: "请选择结束时间" }],
        },
        // 新增功能字段初始化
        data: {
          inspectionRouteName: "",
          inspectionRouteId: "",
          enabled: "1",
          clientType: "1",
          signType: "",
          endTime: "",
          startTime: "",
          inspectionPlanPeriod: "",
        },
      },

      // 查询功能字段配置
      searchOptions: {
        items: [{
            field: "inspectionPlanName",
            title: "巡检计划名称",
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入巡检计划名称"
              }
            }
          },
          {
            field: "inspectionPlanPeriod",
            title: "巡检周期",
            folding: true,
            itemRender: {
              name: "$select",
              optionTypeCode: "inspectionPlanPeriod",
              props: {
                maxlength: 24,
                placeholder: "请选择巡检周期"
              }
            }
          },
          {
            align: "center",
            collapseNode: true,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "查询",
                    status: "primary"
                  }
                },
                {
                  props: {
                    type: "reset",
                    content: "重置"
                  }
                }
              ]
            }
          }
        ],
        data: {
          inspectionPlanPeriod: "",
          inspectionPlanName: ""
        },
      },
    };
  },

  methods: {
    ...mapActions(['submitEventService']),
    submitEvent() {
      let params = this.formOptionPlanStaff.data
      this.submitEventService({
        service: "/wisdom/inspectionPlanStaff",
        params: params,
      }).then(({ code }) => {
        if (code == 200) {
          this.$refs.xModal.close();
          this.$refs.xGrid.getLists();
        }
      });
    },
    closeModal() {
      this.$refs.xModal.close();
    },
    // 指派处理人
    sendSingleEvent(row) {
      this.formOptionPlanStaff.data['staffName'] = row.name
      this.formOptionPlanStaff.data['staffId'] = row.id
      this.formOptionPlanStaff.data['communityId'] = this.formOptions.data['communityId']
      this.formOptionPlanStaff.data['parentId'] = this.formOptions.data['communityId']
      this.formOptionPlanStaff.data['inspectionPlanId'] = this.selectRow.id,
        this.$refs.xModal.close();

    },
    handleNodeClick(treeData, node) {
      this.currNode = node;
      this.$Tools.buildDataToGrid(this.gridOptions.columns, treeData, "parentId");
      this.$Tools.buildDataToGrid(this.formOptions.items, treeData, "parentId");
      this.formOptions.data['parentId'] = node.id;
      this.formOptions.data['communityId'] = node.id;
      this.searchOptions.data['parentId'] = node.id;
      this.$refs.xGrid.getLists();
    }
  },
};
</script>

<style lang="scss" scoped></style>
