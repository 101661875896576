<template>
<div class="main">
  <div class="main_right">
    <VxeBasicTable ref="xGrid" service="/wisdom/inspectionPlanStaff" :gridOptions="gridOptions" :searchOptions="searchOptions">
    </VxeBasicTable>
  </div>
  <vxe-modal ref="xModal" title="选择处理人" width="800" height="500" show-zoom>
    <template v-slot>
      <SelectRepairMaster :currNode="currNode" :selectRow="selectRow" />
    </template>
  </vxe-modal>

</div>
</template>

<script>
import SelectRepairMaster from './SelectRepairMaster.vue'
import { mapActions } from 'vuex';
export default {
  components: {
    SelectRepairMaster
  },
  props: ["currNode", "selectRow"],
  data() {
    return {
      service: '',
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          buttons: [{
            code: "selectStaff",
            name: "选择处理人",
            status: "primary",
            events: {
              click: () => {
                this.$refs.xModal.open();
              }
            }
          }, ],
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60,
          },
          {
            type: "checkbox",
            width: 60,
          },
          {
            field: 'staffName',
            title: '员工名称',
            minWidth: 160,
            editRender: {
              enabled: false,
              name: 'input',
              props: {
                placeholder: "请选择处理人"
              }
            }
          },
          {
            field: "startTime",
            title: "开始时间",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                type: "time",
                editable: false,
                labelFormat: "HH:mm:ss",
                valueFormat: "HH:mm:ss",
                placeholder: "请选择开始时间"
              }
            },
          },
          {
            field: "endTime",
            title: "结束时间",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                type: "time",
                editable: false,
                labelFormat: "HH:mm:ss",
                valueFormat: "HH:mm:ss",
                placeholder: "请选择结束时间"
              }
            },
          },
          {
            fixed: "right",
            title: "操作",
            width: 120,
            editRender: {
              name: "$VxeGridButtons",
              lists: [{
                  name: "edit",
                  status: "primary",
                  label: "编辑",
                  events: {
                    click: (row) => {
                      this.$refs.xGrid.$refs.xGrid.setEditRow(row)
                    }
                  }
                },
                {
                  name: "delete",
                  status: "danger",
                  label: "删除",
                  eventName: "removeEvent"
                }
              ]
            }
          }
        ],
        editRules: {
          startTime: [
            { required: true, message: "请选择开始时间" },
          ],
          endTime: [
            { required: true, message: "请选择结束时间" },
          ],
        },
        editConfig: {},
        exportConfig: {},
        importConfig: {},
      },
      formOptions: {
        // 新增功能字段配置
        width: 800,
        titleWidth: 130,
        mask: false,
        items: [{
          field: "endTime",
          title: "结束时间",
          span: 24,
          itemRender: {
            name: "$input",
            props: {
              type: "time",
              labelFormat: "HH:mm",
              valueFormat: "HH:mm",
              placeholder: "请输入结束时间"
            }
          }
        }],
        // 新增功能字段初始化
        data: {
          communityId: "",
          staffName: "",
          startTime: "",
          endTime: "",
        },
      },
      // 查询功能字段配置
      searchOptions: {
        items: [{
            field: "staffName",
            title: "职工姓名",
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入职工姓名",
              },
            },
          },
          {
            align: "center",
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "查询",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "重置",
                  },
                },
              ],
            },
          },
        ],
        data: {
          parentId: this.selectRow.id,
          communityId: this.selectRow.communityId,
          inspectionPlanId: this.selectRow.id,
          staffName: ''
        },
      },
    };
  },
  created() {},
  mounted() {},
  methods: {
    ...mapActions(["submitEventService"]),

    async sendSingleEvent(row) {
      const $table = this.$refs.xGrid.$refs.xGrid
      const record = {
        staffName: row.name,
        staffId: row.id,
        startTime: "",
        endTime: "",
        communityId: this.selectRow.communityId,
        parentId: this.selectRow.id,
        inspectionPlanId: this.selectRow.id,
      }
      const { row: newRow } = await $table.insertAt(record, -1)
      await $table.setEditCell(newRow, 'startTime')
      this.$refs.xModal.close();
    },
    submitEvent(row) {
      let params = {
        parentId: this.selectRow.communityId,
        communityId: this.selectRow.communityId,
        inspectionRouteId: this.selectRow.id,
        points: [row],
      }
      console.log(params)
      this.submitEventService({
        service: "/wisdom/inspectionRoutePointRel",
        params: params,
      }).then(({ code, data }) => {
        if (code == 200) {
          this.$refs.xGrid.getLists();
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
